import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";


var firebaseConfig = {
    apiKey: "AIzaSyCogref9CKI8Eds1NHSiqOWGdMzN71exdw",
    authDomain: "pwatest-80ba9.firebaseapp.com",
    projectId: "pwatest-80ba9",
    storageBucket: "pwatest-80ba9.appspot.com",
    messagingSenderId: "359725847087",
    appId: "1:359725847087:web:63f05ed5dfc5a68f962cda"
};

const app = initializeApp(firebaseConfig)

getMessaging(app);
